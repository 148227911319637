import React from "react"
import Layout from "../components/layout"
import TopSection from "../components/topsection"
import { graphql } from "gatsby"

const ThanksPage = ({ data, location, lang }) => (
  <Layout location={location} lang="fi">
    <TopSection img={data.img.childImageSharp.fluid} title="SH-Måleri AB" styleClass="proposal-background" location={location} lang={lang} />
    <div className="container mx-auto text-center">
      <h1 className="heading py-5 text-uppercase">Tack!</h1>
      <p className="paragraph pb-5 lead">Kiitos yhteydenotosta. Palaamme mahdollisimman pian.</p>
    </div>
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default ThanksPage
